// Docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md
import {html} from 'lit';
import {offline} from '@thepassle/app-tools/router/plugins/offline.js';

if (!(globalThis as any).URLPattern) {
    await import('urlpattern-polyfill');
}

import {Router} from '@thepassle/app-tools/router.js';
import {lazy} from '@thepassle/app-tools/router/plugins/lazy.js';

const baseURL: string = '/';//(import.meta as any).env.BASE_URL;

export const router = new Router({
    plugins: [
        offline,
        {
            name: 'afterNavigationPlugin',
            afterNavigation: (context) => {
                document.title = pwaName + ' — ' + context.title;
            }
        }
    ],
    routes: [
        {
            path: '/offline',
            title: 'Offline',
            render: () => html`оффлайн`
        },
        {
            path: resolveRouterPath(),
            title: 'Главная',
            plugins: [
                lazy(() => import('./pages/app-home')),
            ],
            render: () => html`<app-home class="w-100"></app-home>`
        },
        {
            path: resolveRouterPath('news'),
            title: 'Новости',
            plugins: [
                lazy(() => import('./pages/app-posts')),
            ],
            render: () => html`<div class="w-100 h-100"><app-posts postType="news" postTitle="Новости"></app-posts></div>`
        },
        {
            path: resolveRouterPath('ads'),
            title: 'Объявления',
            plugins: [
                lazy(() => import('./pages/app-posts')),
            ],
            render: () => html`<div class="w-100 h-100"><app-posts postType="ads" postTitle="Объявления"></app-posts></div>`
        },
        {
            path: resolveRouterPath('ads/:code'),
            title: 'Объявления по тегу',
            plugins: [
                lazy(() => import('./pages/app-posts')),
            ],
            render: ({params}) => html`<div class="w-100 h-100"><app-posts postType="ads" postTitle="Объявления" postTagCode="${params.code}"></app-posts></div>`
        },
        {
            path: resolveRouterPath('settings'),
            title: 'Настройки',
            plugins: [
                lazy(() => import('./pages/app-settings')),
            ],
            render: () => html`<app-settings></app-settings>`
        },
        {
            path: resolveRouterPath('search'),
            title: 'Результаты поиска',
            plugins: [
                lazy(() => import('./pages/app-posts')),
            ],
            render: (context) => html`<div class="w-100 h-100"><app-posts postType="search" postTitle="Результаты поиска" searchQuery="${context.url.searchParams.get('q')}"></app-posts></div>`
        },
        {
            path: resolveRouterPath('install'),
            title: 'Установка',
            plugins: [
                lazy(() => import('./pages/app-install')),
            ],
            render: () => html`<app-install></app-install>`
        },
        {
            path: resolveRouterPath('add'),
            title: 'Разместить объявления',
            plugins: [
                lazy(() => import('./pages/app-add')),
            ],
            render: () => html`<div class="w-100 h-100"><app-add></app-add></div>`
        },
        {
            path: resolveRouterPath('legal'),
            title: 'Правовые документы',
            plugins: [
                lazy(() => import('./pages/app-legal')),
            ],
            render: () => html`<div class="w-100 h-100"><app-legal></app-legal></div>`
        },
        {
            path: resolveRouterPath('feedback'),
            title: 'Обратная связь',
            plugins: [
                lazy(() => import('./pages/app-feedback')),
            ],
            render: () => html`<div class="w-100 h-100"><app-feedback></app-feedback></div>`
        },
    ]
});

export function resolveRouterPath(unresolvedPath?: string, params?: any) {
    let resolvedPath = baseURL;
    if (unresolvedPath) {
        resolvedPath = resolvedPath + unresolvedPath;
    }
    if (params) {
        if (params.tag) {
            resolvedPath = resolvedPath + '?tag=' + params.tag;
        }
    }
    return resolvedPath;
}
