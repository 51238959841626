import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import {resolveRouterPath, router} from '../router';

setBasePath('/dist');

@customElement('app-search')
export class AppSearch extends LitElement {

    @property({type: String})
    searchQuery: string = '';

    handleSearchEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.search();
        }
    }

    handleSearchClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (target.tagName === 'SL-ICON') {
            this.search();
        }
    }

    search() {
        const searchInput = this.shadowRoot?.getElementById('searchInput') as HTMLInputElement | null;
        if (searchInput) {
            const query = searchInput.value.trim();
            if (query !== '') {
                router.navigate(resolveRouterPath('search?q=' + encodeURIComponent(query)));
            }
        }
    }

    render() {
        return html`
            <sl-input id="searchInput" placeholder="Поиск..." @keydown="${this.handleSearchEnter}" value="${this.searchQuery}">
                <sl-icon name="search" slot="suffix" @click="${this.handleSearchClick}"></sl-icon>
            </sl-input>`;
    }
}