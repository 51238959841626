import {LitElement, css, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {resolveRouterPath, router} from '../router';

import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';

import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';

setBasePath('/dist');

@customElement('app-footer')
export class AppFooter extends LitElement {
    static styles = css`
        footer {
            color: var(--sl-color-neutral-600);
            background-color: var(--sl-color-neutral-100);
            padding: 
                    var(--sl-spacing-x-small) 
                    max(var(--sl-spacing-large), env(safe-area-inset-right)) 
                    max(var(--sl-spacing-x-small), env(safe-area-inset-bottom)) 
                    max(var(--sl-spacing-large), env(safe-area-inset-left));
        }

        footer > div {
            margin: auto;
            max-width: 640px;
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @media (prefers-color-scheme: light) {
            footer {
                box-shadow: 0 -10px 10px -15px rgba(0, 0, 0, 0.5);
            }
        }

        sl-icon-button {
            font-size: var(--sl-font-size-x-large);
        }
    `;

    @property({type: Array})
    items = [
        //{ value: 'my-profile', label: 'Мой профиль', icon: 'person-bounding-box' },
        //{ value: 'my-ads', label: 'Мои объявления', icon: 'badge-ad'},
        //{ value: 'my-settings', label: 'Настройки', icon: 'gear' }
        {value: 'feedback', label: 'Обратная связь', icon: 'envelope'},
        {value: 'legal', label: 'Правовые документы', icon: 'briefcase'}
    ];

    firstUpdated() {
        const menu = this.shadowRoot!.querySelector('.menu-value');
        if (menu) {
            menu.addEventListener('sl-select', this.handleSelect);
        }
    }

    disconnectedCallback() {
        const menu = this.shadowRoot!.querySelector('.menu-value');
        if (menu) {
            menu.removeEventListener('sl-select', this.handleSelect);
        }
        super.disconnectedCallback();
    }

    handleSelect = (event: Event) => {
        const detail = (event as CustomEvent).detail;
        const item = detail.item;
        router.navigate(resolveRouterPath(item.value));
    }

    render() {
        return html`
            <footer>
                <div>
                    <sl-icon-button href="${resolveRouterPath()}" name="house" label="Домой"></sl-icon-button>
                    <sl-icon-button href="${resolveRouterPath('ads')}" name="megaphone" label="Объявления"></sl-icon-button>
                    <sl-icon-button href="${resolveRouterPath('news')}" name="newspaper" label="Новости"></sl-icon-button>
                    <sl-dropdown placement="top-start">
                        <sl-icon-button slot="trigger" name="three-dots" label="Меню"></sl-icon-button>
                        <sl-menu class="menu-value">
                            ${this.items.map(item => html`
                                <sl-menu-item value="${item.value}">
                                    <sl-icon slot="prefix" name="${item.icon}"></sl-icon>
                                    ${item.label}
                                </sl-menu-item>
                            `)}
                        </sl-menu>
                    </sl-dropdown>
                </div>
            </footer>
        `;
    }
}