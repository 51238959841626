import {css} from 'lit';

export const baseStyles = css`
    main {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        margin-top: auto;
        padding: 
                var(--sl-spacing-medium) 
                calc(var(--sl-spacing-medium) - env(safe-area-inset-right)) 
                var(--sl-spacing-medium) 
                var(--sl-spacing-medium);
    }

    .container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .d-flex {
        display: flex;
    }

    .justify-content-between {
        justify-content: space-between;
    }

    .justify-content-center {
        justify-content: center;
    }

    .align-items-center {
        align-items: center;
    }

    .flex-row {
        flex-direction: row;
    }

    .flex-column {
        flex-direction: column;
    }

    .flex-grow-1 {
        flex-grow: 1;
    }

    .w-100 {
        width: 100%
    }

    .h-100 {
        height: 100%
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
    }

    .inner-container {
        max-width: 640px;
        width: 100%;
    }

    .overflow-hidden {
        overflow: hidden;
    }
`;