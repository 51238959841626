import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';

@customElement('pwa-install-prompt')
export class PwaInstallPrompt extends LitElement {
    @property({type: Object}) deferredPrompt: any = null;
    @property({type: Boolean}) isInstalled: boolean = false;
    @property({type: Boolean}) isIos: boolean = false;
    @property({type: Boolean}) isInStandaloneMode: unknown = false;

    static styles = css`
        sl-button {
            margin-bottom: 1rem;
        }

        div {
            display: flex;
            align-items: center;
            font-size: 16px;
        }

        span {
            display: inline-flex;
            align-items: center;
            font-size: 24px;
            padding: 0 10px;
        }
    `;

    constructor() {
        super();
        this.isIos = this.detectIos();
        this.isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
        this.checkIfInstalled();

        window.addEventListener('beforeinstallprompt', (e: any) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.requestUpdate();
        });

        // Слушаем событие appinstalled для отслеживания установки PWA
        window.addEventListener('appinstalled', () => {
            this.isInstalled = true;
            this.requestUpdate();
        });
    }

    detectIos() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    async checkIfInstalled() {
        // Метод для проверки, установлено ли приложение
        if ('getInstalledRelatedApps' in window.navigator) {
            const relatedApps: any[] = await (window.navigator as any).getInstalledRelatedApps();
            console.log(relatedApps, window.location.origin);
            this.isInstalled = relatedApps.some((app: any) => app.platform === 'webapp' && app.url === window.location.origin);
            this.requestUpdate();
        } else if (this.isInStandaloneMode || window.matchMedia('(display-mode: standalone)').matches) {
            this.isInstalled = true;
            this.requestUpdate();
        }
    }

    async showInstallPrompt() {
        if (!this.deferredPrompt) return;
        this.deferredPrompt.prompt();
        const choiceResult = await this.deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
            console.log('Пользователь принял установку PWA');
            this.isInstalled = true;
        } else {
            console.log('Пользователь отклонил установку PWA');
        }
        this.deferredPrompt = null;
        this.requestUpdate();
    }

    openApp() {
        // Метод для открытия уже установленного приложения
        console.log('Открытие установленного приложения');
        // Здесь можно добавить логику для открытия приложения, если это возможно
    }

    render() {
        if (this.isInstalled || this.isInStandaloneMode) return html``; // Не показываем ничего, если приложение уже установлено или работает в автономном режиме

        if (this.isInstalled || this.isInStandaloneMode) {
            return html`
                <sl-drawer open placement="bottom" label="${pwaName}" style="--size: auto;">
                    <p>Приложение ${pwaName} уже установлено.</p>
                    <sl-button variant="primary" @click=${this.openApp}>Запустить</sl-button>
                </sl-drawer>
            `;
        }

        if (this.isIos) {
            return html`
                <sl-drawer open placement="bottom" label="${pwaName}" style="--size: auto;">
                    <div>
                        Чтобы установить это приложение, нажмите кнопку
                        <span><sl-icon name="box-arrow-up" aria-hidden="true" library="default"></sl-icon></span>
                        "Поделиться", а затем
                        <span><sl-icon name="plus-square" aria-hidden="true" library="default"></sl-icon></span>
                        "На экран "Домой"".
                    </div>
                </sl-drawer>
            `;
        }

        return html`
            <sl-drawer open placement="bottom" label="${pwaName}" style="--size: auto;">
                <p>Хотите установить приложение ${pwaName}, на ваше устройство?</p>
                <sl-button variant="success" @click=${this.showInstallPrompt}>Установить</sl-button>
            </sl-drawer>
        `;
    }
}