import {LitElement, css, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {resolveRouterPath} from './router';

import '@shoelace-style/shoelace/dist/components/alert/alert.js';

import './pages/app-home';
import './components/app-header';
import './components/app-footer';
import './components/app-loading';
import './components/pwa-install-prompt';
import './styles/global.css';

import {router} from './router';
import {baseStyles} from "./styles/base-styles";


import './components/app-post-detail';

@customElement('app-index')
export class AppIndex extends LitElement {

    @property({type: String}) title = pwaName;
    @property({type: Boolean}) enableBack: boolean = true;

    static styles = [
        baseStyles,
        css`
            sl-icon {
                margin: 0 var(--sl-spacing-x-small);
                font-size: var(--sl-font-size-large);
            }

            @media (max-width: 575.98px) {
                .app-icons {
                    display: none;
                }
            }
        `
    ];

    constructor() {
        super();
        router.addEventListener('route-changed', () => {
            console.log(router.context);
            if (router.context.url.pathname !== '/') {
                this.title = router.context.title;
                this.enableBack = true;
            } else {
                this.title = pwaName;
                this.enableBack = false;
            }
            ym(ymId, 'hit', router.context.url.href, {
                'title': router.context.title,
            });
        });
        this.handlePostsLoaded = this.handlePostsLoaded.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('posts-loaded' as string, this.handlePostsLoaded as EventListener);
    }

    disconnectedCallback() {
        this.removeEventListener('posts-loaded' as string, this.handlePostsLoaded as EventListener);
        super.disconnectedCallback();
    }

    handlePostsLoaded(event: CustomEvent<any>) {
        this.title = event.detail.title;
    }

    firstUpdated() {
        router.addEventListener('route-changed', () => {
            if ('startViewTransition' in document) {
                (document as any).startViewTransition(() => this.requestUpdate());
            } else {
                this.requestUpdate();
            }
        });
    }

    private handleInstallClick() {
        ym(ymId,'reachGoal','clickBtnInstall');
    }

    render() {
        return html`
            <div class="d-flex flex-column overflow-hidden h-100">
                <app-header .title="${this.title}" .enableBack="${this.enableBack}"></app-header>
                <main>
                    <div class="d-flex flex-column h-100">
                        ${!window.matchMedia('(display-mode: standalone)').matches && router.context.url.pathname !== '/install' ? html`
                            <div class="w-100" style="max-width: 640px; margin: auto; padding-bottom: 10px;">
                                <sl-alert variant="neutral" open>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <strong>Приложение ${pwaName}</strong>
                                        <div class="app-icons">
                                            <sl-icon name="google-play"></sl-icon>
                                            <sl-icon name="apple"></sl-icon>
                                            <sl-icon name="windows"></sl-icon>
                                        </div>
                                        <sl-button href="${resolveRouterPath('install')}" @click="${this.handleInstallClick}" variant="success">Установить</sl-button>
                                    </div>
                                </sl-alert>
                            </div>
                        ` : html``}
                        <div class="d-flex justify-content-center align-items-center flex-grow-1">
                            ${router.render()}
                        </div>
                    </div>
                </main>
                <app-footer></app-footer>
            </div>
        `;
    }
}