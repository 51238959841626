import {LitElement, css, html} from 'lit';
import {customElement} from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import {baseStyles} from "../styles/base-styles";

@customElement('app-loading')
export class AppLoading extends LitElement {
    static styles = [
        baseStyles,
        css`
            div {
                display: flex;
                flex-direction: column;
                align-items: center; 
                justify-content: center;
                height: 100%;
            }
            span {
                margin-top: var(--sl-spacing-large);
                font-size: var(--sl-font-size-large);
                color: var(--sl-color-gray-600);
            }
            sl-spinner{
                font-size: var(--sl-font-size-3x-large);
                --track-width: 5px;
                --indicator-color: var(--sl-color-success-500);
                --track-color: var(--sl-color-success-100);
            }
        `
    ];

    render() {
        return html`<div><sl-spinner></sl-spinner><span>Загрузка...</span></div>`;
    }
}