import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/format-date/format-date.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';

@customElement('app-post-detail')
export class AppPostDetail extends LitElement {
    static styles = css`
        sl-dialog::part(panel) {
            width: 640px;
            //height: 98vh !important;
            //max-height: none;
            box-shadow: none;
            padding-top: env(safe-area-inset-top);
            padding-bottom: env(safe-area-inset-bottom);
        }
        sl-dialog::part(panel), sl-dialog::part(overlay) {
            background-color: var(--sl-color-neutral-100);
            color: var(--sl-color-neutral-1000);
        }

        sl-dialog::part(panel) {
            max-height: 100%;
            height: 100%;
        }
        @media screen and (max-width: 420px) {
            sl-dialog::part(panel) {
                max-height: 100%; //calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
            }
        }
        
        @media (max-width: 768px) {
            sl-dialog::part(panel) {
                max-width: 100%;
                width: 100%;
            }
        }

        sl-format-date {
            opacity: 0.6;
            font-size: var(--sl-font-size-small);
        }

        .text {
            padding-bottom: var(--sl-spacing-x-large);
            white-space: pre-line;
        }

        .photos {
            padding-bottom: var(--sl-spacing-x-large);
        }

        .photos img {
            object-fit: contain;
            height: auto;
            width: 100%;
            display: block;
            padding: 5px 0;
        }

        .source {
            font-size: var(--sl-font-size-small);
        }
    `;

    @property({ type: Number })
    postId: number | null = null;

    @property({ type: Object })
    post: any = null;

    @property({ type: String })
    errorMessage: string = '';

    @property({ type: Number })
    currentYear = new Date().getFullYear();

    @property({ type: Boolean })
    loading = false;

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('postId')) {
            if (this.postId !== null) {
                this.loadPost();
            }
        }
    }

    async loadPost() {
        this.post = null;
        this.errorMessage = '';
        this.loading = true;
        this.requestUpdate();

        try {
            const response = await fetch(`/action/post/${this.postId}`);
            const result = await response.json();
            if (result.status !== 'success') {
                const message = result.errors.map((err: any) => err.message).join("\n");
                throw new Error(message || 'Произошла неизвестная ошибка.');
            }
            this.post = result.data;
            this.requestUpdate(); // Обновление компонента после загрузки поста
        } catch (error) {
            this.errorMessage = 'Не удалось загрузить данные.\n Пожалуйста, попробуйте позже.';
            this.showAlert(this.errorMessage);
            console.error(error);
        } finally {
            this.loading = false;
            this.requestUpdate();
        }
    }

    render() {
        if (this.loading) {
            return html`<app-loading></app-loading>`;
        }

        if (this.errorMessage) {
            return html`<div class="error">${this.errorMessage}</div>`;
        }

        if (!this.post) {
            return null;
        }

        const postDate = new Date(this.post.date);
        const postYear = postDate.getFullYear();
        return html`
            ${this.post && !this.errorMessage ? html`
                <sl-dialog open @sl-hide=${this.closePost}>
                    <!--sl-icon-button slot="header-actions" name="three-dots"></sl-icon-button-->
                    <sl-format-date slot="label" .date="${postDate}" hour="numeric" minute="numeric" month="long" day="numeric" .year="${postYear !== this.currentYear ? 'numeric' : undefined}"></sl-format-date>
                    <div>
                        ${this.post.text ? html`<div class="text">${unsafeHTML(this.post.text)}</div>` : ''}
                        ${this.post.photoLinks.length ? html`<div class="photos">${this.post.photoLinks.map((photoLink: string) => html`<img src="${photoLink}" alt="">`)}</div>` : ''}
                    </div>
                    ${this.post.source.site ? html`
                    <div slot="footer" class="source">
                        Источник: <a href="https://${this.post.source.site}" target="_blank">${this.post.source.name}</a><br>
                         <a href="${this.post.source.link}" target="_blank">${this.post.source.link}</a>
                    </div>
                    ` : html`${this.post.source.link.length ? html`<div slot="footer" class="source">Источник: <a href="${this.post.source.link}" target="_blank">${this.post.source.link}</a></div>` : ''}`}
                </sl-dialog>
        ` : null}
        `;
    }

    showAlert(message: string) {
        const alert = document.createElement('sl-alert');
        alert.variant = 'danger';
        alert.closable = true;
        alert.duration = 3000;
        alert.innerText = message;
        document.body.appendChild(alert);
        alert.toast();
    }
    closePost() {
        const dialog = this.shadowRoot?.querySelector('sl-dialog');
        if (dialog) {
            dialog.hide();
        }
        this.dispatchEvent(new CustomEvent('close'));
    }
}
