import {LitElement, css, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import {resolveRouterPath} from '../router';

import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';

import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';

setBasePath('/dist');

@customElement('app-header')
export class AppHeader extends LitElement {
    @property({type: String}) title = pwaName;
    @property({type: Boolean}) enableBack: boolean = true;

    static styles = css`
        header {
            color: var(--sl-color-neutral-600);
            background-color: var(--sl-color-neutral-100);
            padding: var(--sl-spacing-x-small) max(var(--sl-spacing-large), env(safe-area-inset-right)) var(--sl-spacing-x-small) max(var(--sl-spacing-large), env(safe-area-inset-left));
        }
        
        header > div {
            margin: auto;
            max-width: 640px;
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @media (prefers-color-scheme: light) {
            header {
                box-shadow: 0 10px 10px -15px rgba(0, 0, 0, 0.5);
            }
        }

        sl-icon-button {
            font-size: var(--sl-font-size-x-large);
        }

        .empty {
            width: 40px;
            height: 40px;
        }

        .title {
            text-align: center;
            font-weight: bold;
        }
    `;

    render() {
        return html`
            <header>
                <div>
                    <div>${this.enableBack ? html`
                        <sl-icon-button href="${resolveRouterPath()}" name="arrow-left" label="Назад"></sl-icon-button>` : html`
                        <div class="empty"></div>`}
                    </div>
                    <div class="title">${this.title}</div>
                    <div>
                        <sl-icon-button href="${resolveRouterPath('add')}" name="plus-square" label="Добавить" style="font-size: 1.5rem;"></sl-icon-button>
                    </div>
                </div>
            </header>
        `;
    }
}